import React, { useState } from "react";
import DateTimePicker from 'react-datetime-picker';
import dateFormat from 'dateformat';

import IntentionDataService from "../../services/IntentionService";

const AddIntention = () => {
    const initialIntentionState = {
        category_id: "1",
        name: "",
        observation: "",
        date: "",
        permanent: false
    };
    const [intention, setIntention] = useState(initialIntentionState);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setIntention({ ...intention, [name]: value });
    };

    const handleRadioChange = event => {
        const { name, value } = event.target;
        const valueBool = value === "true" ? true : false;
        setIntention({ ...intention, [name]: valueBool });
    };

    const handleDateChange = (value) => {
        setIntention({ ...intention, "date": value });
    };

    const saveIntention = () => {
        const dateString = dateFormat(intention.date, "yyyy-mm-dd");

        var data = {
            category_id: intention.category_id,
            name: intention.name,
            observation: intention.observation,
            date: dateString,
            permanent: intention.permanent
        };

        IntentionDataService.create(data)
            .then(response => {
                console.log(response.data);
                setIntention({
                    category_id: response.data.category_id,
                    name: response.data.name,
                    observation: response.data.observation,
                    date: new Date(response.data.date),
                    permanent: response.data.permanent
                });
                setSubmitted(true);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const newIntention = () => {
        setIntention(initialIntentionState);
        setSubmitted(false);
    };

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Agregar intención para la misa</h4>
                <div className="submit-form">
                    {submitted ? (
                        <div>
                            <p>Intención de {intention.name} agregada exitosamente.</p>
                            <button className="btn btn-primary" onClick={newIntention}>
                                Agregar otra intención
                    </button>
                        </div>
                    ) : (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="category_id">Intención: </label>
                                    <select name="category_id" id="category_id" className="form-control" value={intention.category_id} onChange={handleInputChange}>
                                        <option value="1">Acción de gracias</option>
                                        <option value="2">Cumpleaños</option>
                                        <option value="3">Aniversario</option>
                                        <option value="4">Salud</option>
                                        <option value="5">Eterno descanso</option>
                                        <option value="6">Intención particular</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="name">Nombre de la persona por la que se pide: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        required
                                        value={intention.name}
                                        onChange={handleInputChange}
                                        name="name"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="observation">Observación (opcional): </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="observation"
                                        required
                                        value={intention.observation}
                                        onChange={handleInputChange}
                                        name="observation"
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="pr-2">Calendarizar: </label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="permanent" value="false" checked={!intention.permanent ? "checked" : ""} onChange={handleRadioChange} />
                                        <label className="form-check-label">Por fecha</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="permanent" value="true" checked={intention.permanent ? "checked" : ""} onChange={handleRadioChange} />
                                        <label className="form-check-label">Permanente</label>
                                    </div>
                                </div>

                                {intention.permanent === false ? (
                                    <div className="form-group">
                                        <label htmlFor="date">Fecha de la misa: </label>
                                        <DateTimePicker required="true" name="date" value={intention.date} onChange={handleDateChange} format="dd-MM-y" locale="es-GT" />
                                    </div>
                                ) : ""}

                                <button onClick={saveIntention} className="btn btn-primary">
                                    Enviar
                        </button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default AddIntention;
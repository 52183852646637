import React, { useState, useEffect, Fragment } from "react";

import IntentionsList from "../../components/intentions/IntentionsList";

const Home = () => {
    return (
        <div>
            <IntentionsList type="today" />
            <IntentionsList type="tomorrow" />
        </div>
    );
};

export default Home;
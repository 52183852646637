import http from "../http-common";

const getReadingsList = (type) => {
    if (type === "today") {
        return http.get("/readings/today");
    } else if (type === "tomorrow") {
        return http.get("/readings/tomorrow");
    } else {
        return http.get("/readings");
    }
};

const get = id => {
    return http.get(`/readings/${id}`);
};

const create = data => {
    return http.post("/readings", data);
};

const update = (id, data) => {
    return http.put(`/readings/${id}`, data);
};

const remove = id => {
    return http.delete(`/readings/${id}`);
};

export default {
    getReadingsList,
    get,
    create,
    update,
    remove
};
import React from "react";
import MassCountDown from "../../../components/CountDown/CountDown";
import Today from "../../../components/Today/Today";
import './Intro.scss';

const Intro = () => {
    return (
        <div id="intro">
            <div className="video-overlay"></div>
            <video autoPlay muted loop id="background-video">
                <source src="/video/background.mp4" type="video/mp4" />
            </video>
            <div className="content-overlay d-flex flex-column flex-grow-1 justify-content-center">
                <div className="main-content">
                    <div className="heading">SANTA MISA</div>
                    <MassCountDown />            
                    <Today />
                </div>
            </div>
        </div>
    );
};

export default Intro;
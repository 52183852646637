import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';

import IntentionDataService from "../../services/IntentionService";

const IntentionsRoll = () => {
    const [intentionsList, setIntentionsList] = useState([]);

    const categories = [
        { "id": 1, "name": "Acción de gracias" },
        { "id": 2, "name": "Acción de gracias (Cumpleaños)" },
        { "id": 3, "name": "Acción de gracias (Aniversario)" },
        { "id": 4, "name": "Salud" },
        { "id": 5, "name": "Eterno descanso" },
        { "id": 6, "name": "Intención particular" },
    ]

    useEffect(() => {
        retrieveIntentions();
    }, []);

    const retrieveIntentions = () => {
        IntentionDataService.getIntentionsList("today").then(response => {
            setIntentionsList(response.data);
            console.log(response.data);
        }).catch(e => {
            setIntentionsList([]);
            console.log(e);
        });
    };

    return (
        <div className="roll">
            {intentionsList && categories.map(
                category => {
                    return (
                        intentionsList.filter(intention => category.id === intention.category_id).map((intention, index) => (
                            <Fragment key="{intention.id}">
                                {index === 0 ? <h3>{category.name}</h3> : ""}
                                <ul>
                                    <li className="roll__name">{intention.name}</li>
                                </ul>
                            </Fragment>
                        ))
                    )
                }
            )}
        </div>
    );
};

export default IntentionsRoll;
import React from "react";

const End = () => {
    const setDateZero = (date) => {
        return date < 10 ? '0' + date : date;
    }

    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const today = new Date();
    const pretty_date = setDateZero(today.getDate()) + " de " + monthNames[today.getMonth()] + " " + today.getFullYear();

    return (
        <div id="intro">
            <div className="video-overlay"></div>
            <video autoPlay muted loop id="background-video">
                <source src="/video/background.mp4" type="video/mp4" />
            </video>
            <div className="content-overlay d-flex flex-column flex-grow-1 justify-content-center">
                <div className="main-content">
                    <div className="heading">SANTA MISA</div>
                    <div className="container">
                        <div className="row text-center">
                            <div className="col end display-4">
                                Gracias por asistir
                            </div>
                        </div>
                    </div>            
                    <div className="container today display-3">{pretty_date}</div>
                </div>
            </div>
        </div>
    );
};

export default End;
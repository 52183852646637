import React from "react";
import "./sass/variables.scss";
import "./sass/theme.scss";
import "./sass/base.scss";

import Routers from "./router";

function App() {
  return (
    <Routers />
  );
}

export default App;

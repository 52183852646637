import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import dateFormat from 'dateformat';

import ReadingsDataService from "../../services/ReadingsService";

const EditReadings = props => {
    const initialReadingsState = {
        id: null,
        date: "",
        first_reading: "",
        first_reading_verse: "",
        psalm: "",
        psalm_response: "",
        psalm_response_verse: "",
        second_reading: "",
        second_reading_verse: "",
        gospel: "",
        gospel_verse: ""
    };
    const [readings, setReadings] = useState(initialReadingsState);
    const [submitted, setSubmitted] = useState(false);

    const getReadings = id => {
        ReadingsDataService.get(id)
            .then(response => {
                setReadings({
                    id: response.data.id,
                    date: new Date(response.data.date),
                    first_reading: response.data.first_reading,
                    first_reading_verse: response.data.first_reading_verse,
                    psalm: response.data.psalm,
                    psalm_response: response.data.psalm_response,
                    psalm_verse: response.data.psalm_verse,
                    second_reading: response.data.second_reading,
                    second_reading_verse: response.data.second_reading_verse,
                    gospel: response.data.gospel,
                    gospel_verse: response.data.gospel_verse
                });
            })
            .catch(e => {
                console.log(e);
            });
    };

    useEffect(() => {
        getReadings(props.match.params.id);
    }, [props.match.params.id]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setReadings({ ...readings, [name]: value });
    };

    const handleDateChange = (value) => {
        setReadings({ ...readings, "date": value });
    };

    const saveReadings = () => {
        const dateString = dateFormat(readings.date, "yyyy-mm-dd");

        var data = {
            date: dateString,
            first_reading: readings.first_reading,
            first_reading_verse: readings.first_reading_verse,
            psalm: readings.psalm,            
            psalm_response: readings.psalm_response,
            psalm_verse: readings.psalm_verse,
            second_reading: readings.second_reading,
            second_reading_verse: readings.second_reading_verse,
            gospel: readings.gospel,
            gospel_verse: readings.gospel_verse
        };

        ReadingsDataService.update(readings.id,data)
            .then(response => {
                console.log(response.data);
                setReadings({
                    date: new Date(response.data.date),
                    first_reading: response.data.first_reading,
                    first_reading_verse: response.data.first_reading_verse,
                    psalm: response.data.psalm,
                    psalm_response: response.data.psalm_response,
                    psalm_verse: response.data.psalm_verse,
                    second_reading: response.data.second_reading,
                    second_reading_verse: response.data.second_reading_verse,
                    gospel: response.data.gospel,
                    gospel_verse: response.data.gospel_verse
                });
                setSubmitted(true);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Editar lecturas para la misa</h4>
                <div className="submit-form">
                    {submitted ? (
                        <div>
                            <p>Se editaron las lecturas del día {readings.date.getDate()}/{readings.date.getMonth() + 1}/{readings.date.getFullYear()}</p>
                            <Link to={"/admin/readings"} className="btn btn-primary">
                                Ver lecturas
                            </Link>
                        </div>
                    ) : (
                            <div className="container">
                                <div className="form-group">
                                    <label htmlFor="date">Fecha: </label>
                                    <DateTimePicker required="true" name="date" value={readings.date} onChange={handleDateChange} format="dd-MM-y" locale="es-GT" />
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label htmlFor="first_reading">Primera Lectura: </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Libro" 
                                            id="first_reading"
                                            required
                                            value={readings.first_reading}
                                            onChange={handleInputChange}
                                            name="first_reading"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="first_reading_verse">Versículo</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="first_reading_verse"
                                            placeholder="Versículo" 
                                            aria-label="Versículo"
                                            value={readings.first_reading_verse}
                                            onChange={handleInputChange}
                                            name="first_reading_verse"
                                        />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                    <label htmlFor="psalm">Salmo: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="psalm"
                                        placeholder="Libro"
                                        required
                                        value={readings.psalm}
                                        onChange={handleInputChange}
                                        name="psalm"
                                    />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="psalm_verse">Versículo</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="psalm_verse"
                                            placeholder="Versículo" 
                                            aria-label="Versículo"
                                            value={readings.psalm_verse}
                                            onChange={handleInputChange}
                                            name="psalm_verse"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="psalm_response"
                                        placeholder="Respuesta al Salmo" 
                                        required
                                        value={readings.psalm_response}
                                        onChange={handleInputChange}
                                        name="psalm_response"
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label htmlFor="second_reading">Segunda Lectura: </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="second_reading"
                                            placeholder="Libro" 
                                            value={readings.second_reading}
                                            onChange={handleInputChange}
                                            name="second_reading"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="second_reading_verse">Versículo</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="second_reading_verse"
                                            placeholder="Versículo" 
                                            aria-label="Versículo"
                                            value={readings.second_reading_verse}
                                            onChange={handleInputChange}
                                            name="second_reading_verse"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label htmlFor="gospel">Evangelio: </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="gospel"
                                            placeholder="Libro" 
                                            required
                                            value={readings.gospel}
                                            onChange={handleInputChange}
                                            name="gospel"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="gospel_verse">Versículo</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="gospel_verse"
                                            placeholder="Versículo" 
                                            aria-label="Versículo"
                                            value={readings.gospel_verse}
                                            onChange={handleInputChange}
                                            name="gospel_verse"
                                        />
                                    </div>
                                </div>
                                <button onClick={saveReadings} className="btn btn-primary">Enviar</button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default EditReadings;
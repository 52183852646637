import http from "../http-common";

const getIntentionsList = (type) => {
    if (type === "today") {
        return http.get("/intentions/today");
    } else if (type === "tomorrow") {
        return http.get("/intentions/tomorrow");
    } else if (type === "permanent") {
        return http.get("/intentions/permanent");
    } else {
        return http.get("/intentions");
    }
};

const get = id => {
    return http.get(`/intentions/${id}`);
};

const create = data => {
    return http.post("/intentions", data);
};

const update = (id, data) => {
    return http.put(`/intentions/${id}`, data);
};

const remove = id => {
    return http.delete(`/intentions/${id}`);
};

export default {
    getIntentionsList,
    get,
    create,
    update,
    remove
};
import React from 'react'
import {isMobile, isAndroid, isIOS} from "react-device-detect";

//https://app.urlgeni.us/

export default function facebookredirect() {
    try{
        if(!isMobile){
            console.log("Mobile");
            window.location.href = 'https://facebook.com/santamisagt';
        }
        else if (isIOS){
            console.log("IOS");
            // window.location.href = 'fb://page/218894669827000';
            // window.location.href = 'fb://facewebmodal/f?href=https://facebook.com/santamisagt'
            window.location.href ='fb://page/?id=108587750857693';
            // window.location.href = 'https://facebook.com/santamisagt';
        }
        else if (isAndroid){
            console.log("Android");
            window.location.href = 'fb://facewebmodal/f?href=https://facebook.com/santamisagt'
        }
        else{
            window.location.href = 'https://facebook.com/santamisagt';
        }
    }
    catch {
        window.location.href = 'https://facebook.com/santamisagt';
    }
    setTimeout(()=>{
        window.location.href = 'https://facebook.com/santamisagt';
    },500);
    

    return (
        <div>
            
        </div>
    )
}

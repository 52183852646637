
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import MainLayout from "../containers/layouts/MainLayout";
import SimpleLayout from "../containers/layouts/SimpleLayout"

import Home from "../containers/views/Home";
import Intentions from "../containers/views/Intentions";
import AddIntention from "../components/intentions/AddIntention";
import EditIntention from "../components/intentions/EditIntention";
import Readings from "../containers/views/Readings";
import AddReadings from "../components/readings/AddReadings";
import EditReadings from "../components/readings/EditReadings";
import ShowReading from "../components/readings/ShowReading";
import IntentionsRoll from "../components/intentions/IntentionsRoll";
import Intro from "../containers/views/Intro/Intro";
import End from "../containers/views/End";
import FacebookRedirect from "../components/FacebookRedirect/FacebookRedirect";
import Login from "../containers/views/Login";
import Logout from "../components/auth/Logout";

const router = () => {
    return (
        <Switch>
            <Route exact path="/" component={FacebookRedirect} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route path="/admin">
                <Admin />
            </Route>

            <Route path="/user">
                <User />
            </Route>

            <Route path="/obs">
                <OBS />
            </Route>
        </Switch>
    )
}

export default router;

function Admin() {
    let { path } = useRouteMatch();
    return (
        <MainLayout>
            <Switch>
                <Route exact path={`${path}/home`} component={Home} />
                <Route exact path={`${path}/intentions`} component={Intentions} />
                <Route exact path={`${path}/intentions/add`} component={AddIntention} />
                <Route exact path={`${path}/intentions/edit/:id`} component={EditIntention} />
                <Route exact path={`${path}/readings`} component={Readings} />
                <Route exact path={`${path}/readings/add`} component={AddReadings} />
                <Route exact path={`${path}/readings/edit/:id`} component={EditReadings} />
            </Switch>
        </MainLayout>
    );
}

function User() {
    let { path } = useRouteMatch();
    return (
        <SimpleLayout>
            <Switch>                
                <Route exact path={`${path}/add_intention`} component={AddIntention} />            
            </Switch>
        </SimpleLayout>
    );
}

function OBS() {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}/intro`} component={Intro} />
            <Route exact path={`${path}/intenciones`} component={IntentionsRoll} />
            <Route path={`${path}/primeralectura`} render={(props) => (
                <ShowReading {...props} reading="first_reading" />
            )} />
            <Route path={`${path}/segundalectura`} render={(props) => (
                <ShowReading {...props} reading="second_reading" />
            )} />
            <Route path={`${path}/salmo`} render={(props) => (
                <ShowReading {...props} reading="psalm" />
            )} />
            <Route path={`${path}/evangelio`} render={(props) => (
                <ShowReading {...props} reading="gospel" />
            )} />
            <Route exact path={`${path}/end`} component={End} />
        </Switch>
    );
}
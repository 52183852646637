import React from "react";

import IntentionsList from "../../components/intentions/IntentionsList";

const Intentions = () => {
    return (
        <IntentionsList />
    );
};

export default Intentions;
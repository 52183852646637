import React from "react";

const Today = () => {
    const setDateZero = (date) => {
        return date < 10 ? '0' + date : date;
    }

    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const dayNames = [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ];
    const today = new Date();
    const pretty_date = setDateZero(dayNames[today.getDay()] + " " + today.getDate()) + " de " + monthNames[today.getMonth()] + " " + today.getFullYear();

    return (
        <div className="container today">{pretty_date}</div>
    );
}

export default Today;
import React from 'react';
import LoginButton from '../../components/auth/LoginButton';

export default function Login() {
    return (
        <div className='wrapper'>
            <div className="container h-100 d-flex justify-content-center">
                <div className="card my-auto">
                    <div className="card-body px-5 py-5">
                        <h1 className="title display-3">Santa Misa </h1>
                        <h3 className="title text-center">GUATEMALA</h3>
                        <div className="pt-4 text-center">
                            <LoginButton />
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    )
}

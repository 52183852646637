import React from "react";
import Countdown, { zeroPad } from 'react-countdown';
import './CountDown.scss';

const CountDown = () => {
    const setDateZero = (date) => {
        return date < 10 ? '0' + date : date;
    }

    const today = new Date();
    const end_date = new Date(today.getFullYear() + '-' + setDateZero(today.getMonth() + 1) + '-' + setDateZero(today.getDate()) + 'T23:00:00Z');

    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (
                <div className="row text-center">
                    <div className="col-12 starting-soon">
                        Empezará pronto
                    </div>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div className="row time-left">
                    {hours > 0 ? (<div className="col time-unit px-4"><div className="unit-time-left hours">{zeroPad(hours)}</div><div className="time-name">HORAS</div></div>) : ""}
                    <div className="col time-unit px-4"><div className="unit-time-left minutes">{zeroPad(minutes)}</div><div className="time-name">MINUTOS</div></div>
                    <div className="col time-unit px-4"><div className="unit-time-left seconds">{zeroPad(seconds)}</div><div className="time-name">SEGUNDOS</div></div>
                </div>
            );
        }
    };

    return (
        <div className="countdown container">
            <Countdown date={end_date} renderer={countdownRenderer} /> 
        </div>
    );
}

export default CountDown;
import React, { useState, useEffect } from "react";
import ReadingsDataService from "../../services/ReadingsService";
import styled, { keyframes } from 'styled-components';
import { fadeInLeftBig } from 'react-animations';
import "./ShowReading.scss";

const FadeInLeftBig = styled.div`animation: 2s ${keyframes `${fadeInLeftBig}`} `;

const ShowReadings = (props) => {
    const [todayReadings, setTodayReadings] = useState({});
    const [currentReading, setCurrentReading] = useState({});

    useEffect(() => {
        retrieveReadings();
    }, []);

    const changeReading = (data) => {
        let title = "";
        let reading = "";
        let reading_verse = "";
        console.log(todayReadings);
        if (props.reading === "first_reading") {
            title = "Primera Lectura";
            reading = data.first_reading;
            reading_verse = data.first_reading_verse;
        } else if (props.reading === "psalm") {
            title = "Salmo Responsorial"
            reading = data.psalm + ': "' + data.psalm_response + '"';
            reading_verse = data.psalm_verse;
        } else if (props.reading === "second_reading") {
            title = "Segunda Lectura"
            reading = data.second_reading;
            reading_verse = data.second_reading_verse;
        } else if (props.reading === "gospel") {
            title = "Evangelio"
            reading = data.gospel;
            reading_verse = data.gospel_verse;
        }
        setCurrentReading({ title: title, reading: reading , verse: reading_verse});
    };

    const retrieveReadings = () => {
        ReadingsDataService.getReadingsList("today").then(response => {
            setTodayReadings(response.data[0]);
            changeReading(response.data[0]);
        }).catch(e => {
            setTodayReadings([]);
            console.log(e);
        });
    };

    return (
        currentReading?(
            <FadeInLeftBig id="reading">
                <div id="reading-title" className="text">
                    {currentReading.title}
                </div>
                <div id="reading-reading" className="text">
                    {currentReading.reading} 
                    {(currentReading.verse) ?<span id="reading-verse">({currentReading.verse})</span> : ""}
                </div>
            </FadeInLeftBig>
        ):""
    );
};

export default ShowReadings;
import React from "react";

import ReadingsList from "../../components/readings/ReadingsList";

const Readings = () => {
    return (
        <ReadingsList />
    );
};

export default Readings;
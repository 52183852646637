import React from "react";
import {Link} from "react-router-dom";

const MainLayout = ({ children }) => (
    <div className='wrapper'>
        <nav className="navbar navbar-expand navbar-dark bg-primary">
            <div className="navbar-brand">
                Santa Misa GT
            </div>
            <div className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link to={"/admin/home"} className="nav-link">Inicio</Link>
                </li>
                <li className="nav-item">
                    <Link to={"/admin/intentions"} className="nav-link">Intenciones</Link>
                </li>
                <li className="nav-item">
                    <Link to={"/admin/readings"} className="nav-link">Lecturas</Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Santoral y festividades</a>
                </li>
                <li className="nav-item">
                    <Link to={"/logout"} className="nav-link">Cerrar sesión</Link>
                </li>
            </div>
        </nav>
        <div className="container mt-3 pb-3">
            {children}
        </div>
    </div>
);
export default MainLayout;
import React,  { useState } from 'react';
import { Facebook } from 'react-bootstrap-icons';
import { useLocation } from "react-router-dom";
import jwt from 'jwt-decode';

export default function LoginButton() {
    const [redirected, setRedirected] = useState(false);

    const redirectTo = (url_path) => {
        setRedirected(true);
        window.location = url_path;
    };

    const authFB = () => {
        setRedirected(true);
        window.location = process.env.REACT_APP_API_URL + "/auth/facebook";
    }

    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    let status = new URLSearchParams(search).get('status');

    if (status === 'success') {
        if (token) {
            let jwt_user = jwt(token);            
            localStorage.setItem('token',token);
            localStorage.setItem('iat',jwt_user.iat);
            localStorage.setItem('name',jwt_user.user.name);
            localStorage.setItem('admin',jwt_user.user.admin);
            if (jwt_user.user.admin === "1") {
                redirectTo('/admin/home');
            } else {
                redirectTo('/user/add_intention');
            }
        }
    }

    if (status === 'error') {
        localStorage.setItem('token',null); 
    }

    const storage_token = localStorage.getItem('token');
    if (storage_token !== null) {        
        const iat = localStorage.getItem('iat');
        if (iat < Date.now()) {
            status = 'success';
            if (localStorage.getItem('admin') === "1") {
                redirectTo('/admin/home');
            } else {
                redirectTo('/user/add_intention');
            }
        }
    }

    return (
        <div>
            {redirected ? 
            (<div className="spinner-border text-primary" role="status">
                <span className="sr-only">Cargando...</span>
            </div>):
            (<button onClick={authFB} className="btn btn-primary">
                <Facebook color="white" size={25} />  <span className="ml-1">Iniciar sesión con Facebook</span>
            </button>)
            }
        </div>
    )
}


import React from "react";

const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('iat');
    localStorage.removeItem('name');
    localStorage.removeItem('admin');

    window.location = "https://santamisa.gt";
}

export default Logout;